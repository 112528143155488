import AWS from 'aws-sdk';

let listObjects = async props => {
  let { accessKeyId, secretAccessKey, region, bucket, prefix } = props
  if (!secretAccessKey || !accessKeyId) return []

  let s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
    region,
  });

  let isTruncated = true;
  let marker;
  let allObjects = [];

  while (isTruncated) {
    let params = {
      Bucket: bucket,
      Prefix: prefix,
      MaxKeys: 1000,
      Marker: marker
    };

    try {
      let response = await s3.listObjectsV2(params).promise().catch(console.error);
      if (!Array.isArray(response?.Contents)) break

      await Promise.all(
        response.Contents.map(async item => {
          const params = {
            Bucket: bucket,
            Key: item.Key,
            Expires: 10 * 60 * 60
          };
          try {
            let url = await s3.getSignedUrl('getObject', params)
            if (!url) return

            allObjects.push({ ...item, url });
          } catch (error) {
            console.error(error)
          }
        })
      )

      isTruncated = response.IsTruncated;
      if (isTruncated) {
        marker = response.Contents.slice(-1).at(0).Key;
      }
    } catch (err) {
      console.error(err.message);
      break;
    }
  }
  allObjects = allObjects.sort((a, b) => b.LastModified.getTime() - a.LastModified.getTime())
  
  return allObjects
};

export default listObjects
