// src/components/TemplateForm.js
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Handlebars from 'handlebars';
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import AWS from 'aws-sdk';
import { useAWS } from '../aws-context';

let {
  REACT_APP_SOURCE_EMAIL_ADDRESS
} = process.env

let TemplateForm = props => {
  let params = useParams()
  let navigate = useNavigate();
  let [templateId, setTemplateId] = useState('');
  let [htmlPart, setHtmlPart] = useState('');
  let [jsonExample, setJsonExample] = useState('{}');
  let [renderedHtml, setRenderedHtml] = useState('');
  let { credentials } = useAWS();
  let [email, setEmail] = useState('')
  let [status, setStatus] = useState({})

  useEffect(() => {
    let jsonData = {}

    try {
      jsonData = JSON.parse(jsonExample);
    } catch {}

    try {
      let template = Handlebars.compile(htmlPart);
      let result = template(jsonData);
      setRenderedHtml(result);
      setStatus({})
    } catch (error) {
      console.error('Error rendering template with example data', error);
      setStatus({ error: error.message, save: false })
      setRenderedHtml(`
        <div><strong style="color: red">TEMPLATE CON ERROR</strong></div>
        <div style="white-space:pre-line">${error.message}</div>
      `);
    }
  }, [htmlPart, jsonExample]);

  useEffect(() => {
    setTemplateId(params.templateId || '')
    setHtmlPart('')
  }, [params.templateId])

  useEffect(() => {
    if (credentials?.accessKeyId && params.bucket && params.templateId) {
      let s3 = new AWS.S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        region: credentials.region
      });

      let getTemplate = async () => {
        try {
          let payload = {
            Bucket: params.bucket,
            Key: `templates/${params.templateId}`
          };

          let data = await s3.getObject(payload).promise().catch(console.error);
          let html = data?.Body?.toString()

          setHtmlPart(html || '')
        } catch (error) {
          console.error(error);
        }
      };

      getTemplate()
    }
  }, [credentials, params.bucket, params.templateId]);

  let sendEmail = async () => {
    try {
      if (status.loading || !credentials?.accessKeyId) return

      setStatus({ loading: true })

      let payload = {
        Source: `"Prueba" <${REACT_APP_SOURCE_EMAIL_ADDRESS}>`,
        Destination: {
          ToAddresses: [email]
        },
        Message: {
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: renderedHtml
            }
          },
          Subject: {
            Charset: 'UTF-8',
            Data:  ['Test', 'TemplateManager', templateId].filter(Boolean).join('::')
          }
        }
      };

      let ses = new AWS.SES({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        region: credentials.region
      });

      try {
        let message = await ses.sendEmail(payload).promise()
        setStatus({ message })
      } catch (e) {
        setStatus({ error: e.message })
      }
    } catch (e) {
      console.error(e)
    }

  }

  let onChangeEditorHTML = useCallback((val) => {
    setHtmlPart(val);
  }, []);

  let onChangeEditorJSON = useCallback((val) => {
    setJsonExample(val);
  }, []);

  let handleSubmit = async event => {
    if (status.save === false) return alert('No se puede guardar el template porque tiene errores')
    if (status.loading) return

    setStatus({ loading: true })
    event.preventDefault();

    let s3 = new AWS.S3({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      region: credentials.region
    });

    let key = `templates/${templateId}`

    let payload = {
      Bucket: params.bucket,
      Body: htmlPart,
      Key: key,
      ContentType: 'text/html'
    }

    try {
      await s3.putObject(payload).promise();
    } catch (error) {
      console.error(error);
      alert('Error updating template');
    }

    setStatus({ loading: false })
    navigate(`/s3/${params.bucket}/${key}/edit`);
  };

  return (
    <div className="flex bg-white">
      <div className="w-[1024px] h-screen overflow-y-auto p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Nombre Template"
            value={templateId}
            onChange={(e) => setTemplateId(e.target.value)}
            disabled={!!params.templateId}
            className="w-full p-2 bg-gray-100 rounded"
          />
          <CodeMirror
            value={htmlPart}
            theme={vscodeDark}
            height="768px"
            extensions={[html({ jsx: true })]}
            onChange={onChangeEditorHTML}
          />
          {status.save !== false && (
            <div className={`flex items-center gap-3 ${status.loading ? 'opacity-50' : ''}`}>
              <button
                type="submit"
                disabled={!!status.loading}
                className="px-4 py-2 bg-gray-800 text-white rounded"
              >
                Guardar
              </button>

              <div className="bg-gray-100 rounded grow">
                <input
                  type="email"
                  disabled={!!status.loading}
                  value={email || ''}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Direccion email"
                  className="w-full px-3 py-2 bg-transparent"
                />
              </div>

              <button
                type="button"
                disabled={!!status.loading}
                onClick={sendEmail}
                className={`px-4 py-2 bg-blue-600 text-white rounded`}
              >
                Enviar
              </button>
            </div>
          )}

          {!!status.error && (
            <div className="whitespace-preline text-red-600 bg-red-100 p-3 font-mono border border-red-300 rounded">{status.error}</div>
          )}

          <CodeMirror
            value={jsonExample}
            theme={vscodeDark}
            height="452px"
            extensions={[javascript({ jsx: true })]}
            onChange={onChangeEditorJSON}
          />
        </form>
      </div>
      <div className="h-screen grow overflow-y-auto p-4">
        <iframe title="emailpreview" srcDoc={renderedHtml} style={{ height: '700px' }} frameBorder="0" className="w-full"></iframe>
      </div>
    </div>
  );
};

export default TemplateForm;
