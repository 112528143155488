// src/aws-context.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import AWS from 'aws-sdk';

const AWSContext = createContext();

export const useAWS = () => useContext(AWSContext);

export const AWSProvider = ({ children }) => {
  const [credentials, setCredentials] = useState(() => {
    const savedCredentials = localStorage.getItem('awsCredentials');
    return savedCredentials ? JSON.parse(savedCredentials) : null;
  });

  const configureAWS = ({ accessKeyId, secretAccessKey, region }) => {
    const creds = { accessKeyId, secretAccessKey, region };
    AWS.config.update(creds);

    setCredentials(creds);
    localStorage.setItem('awsCredentials', JSON.stringify(creds));
  };

  useEffect(() => {
    if (credentials) {
      AWS.config.update(credentials);
    }
  }, [credentials]);

  return (
    <AWSContext.Provider value={{ credentials, configureAWS }}>
      {children}
    </AWSContext.Provider>
  );
};
