// src/App.js
import { Routes, Route } from 'react-router-dom';
import TemplateForm from './components/TemplateForm';
import Sidebar from './components/Sidebar';
import AWSConfigForm from './components/AWSConfigForm';
import Deliveries from './components/Deliveries';
import { useAWS } from './aws-context';

let App = () => {
  let { credentials } = useAWS();

  if (!credentials) {
    return (
      <div className="max-w-6xl mx-auto p-4 min-h-screen bg-gray-50 text-gray-900 flex items-center justify-center">
        <div className="w-full max-w-md">
          <h1 className="text-3xl font-bold mb-8 text-center">
            AWS SES Template Manager
          </h1>
          <AWSConfigForm />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-gray-50 text-gray-900 flex overflow-hidden">
      <div className="w-[300px] max-w-[300px] min-w-[300px] bg-white border-r border-gray-200 h-full overflow-y-auto">
        <div>
          <Sidebar />
        </div>
        <div className="mb-8 text-center">
          <AWSConfigForm />
        </div>
      </div>
      <div className="grow h-full">
        <Routes>
          <Route path="/s3/:bucket/templates/new" element={<TemplateForm />} />
          <Route path="/s3/:bucket/templates/:templateId/edit" element={<TemplateForm />} />
          <Route path="/s3/:bucket/emails" element={<Deliveries />} />
          <Route path="/s3/:bucket/emails/:address" element={<Deliveries />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
