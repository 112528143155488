// src/components/AWSConfigForm.js
import React, { useState, useEffect } from 'react';
import { useAWS } from '../aws-context';
import { FiSettings } from 'react-icons/fi';

const AWSConfigForm = () => {
  const { configureAWS, credentials } = useAWS();
  const [formData, setFormData] = useState({
    accessKeyId: '',
    secretAccessKey: '',
    region: '',
  });
  const [showForm, setShowForm] = useState(!credentials);

  useEffect(() => {
    if (credentials) {
      setFormData({
        accessKeyId: '',
        secretAccessKey: '',
        region: '',
        ...credentials
      });
    }
  }, [credentials]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = { ...formData }
    delete payload.source
    configureAWS(payload);
    setShowForm(false);
  };

  return (
    <div>
      {credentials && !showForm ? (
        <div>
          <button onClick={() => setShowForm(true)} className="text-gray-500 hover:text-gray-900">
            <FiSettings size={24} />
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          {credentials && (
            <div>
              <button onClick={() => setShowForm(false)} className="text-gray-500 hover:text-gray-900">
                <FiSettings size={24} />
              </button>
            </div>
          )}
          {Object.keys(formData).map((key) => (
            <input
              key={key}
              type="text"
              name={key}
              placeholder={`AWS ${key.replace(/([A-Z])/g, ' $1').trim()}`}
              value={formData[key]}
              onChange={handleChange}
              className="w-full p-2 bg-gray-100 rounded"
            />
          ))}
          <button type="submit" className="px-4 py-2 bg-gray-800 text-white rounded">
            Save AWS Configuration
          </button>
        </form>
      )}
    </div>
  );
};

export default AWSConfigForm;
