// src/components/ListTemplates.js
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAWS } from '../aws-context';
import listObjects from './listObjects';

let sort = (a, b) => {
  if (a.Name < b.Name) return -1
  if (a.Name > b.Name) return 1
  return 0
}

let ListTemplates = () => {
  let [templates, setTemplates] = useState([]);
  let [buckets, setBuckets] = useState([])
  let [bucket, setBucket] = useState()
  let navigate = useNavigate()
  let location = useLocation()
  let { credentials } = useAWS();

  let onSelectBucket = e => {
    setBucket(e.target.value)
    navigate(`/s3/${e.target.value}`);
  }

  let fetchTemplates = async () => {
    setTemplates([])
    if (!bucket || !credentials?.accessKeyId) return
    try {
      let prefix = 'templates'
      let payload = { ...credentials, prefix, bucket }
      let templates = await listObjects(payload)
      let regexp = new RegExp(`${prefix}/`, 'g')
      templates = templates.map(template => ({ ...template, name: template.Key.replace(regexp, '') })).filter(t => t.name)
      setTemplates(templates);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let parts = location.pathname.split('/').filter(Boolean)
    let bucket = parts.find(p => p.includes('emails') && p.includes('.drapp.la')) || ''
    if (bucket) {
      setBucket(bucket)
    }
  }, [location.pathname])

  useEffect(() => {
    if (credentials) {
      (async () => {
        if (!credentials || !credentials.accessKeyId) return

        let buckets = await fetch('/aws/s3/buckets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        }).then(res => res.json()).catch(console.error);
        if (Array.isArray(buckets)) {
          buckets = buckets.sort((a, b) => {
            if (a.Name.includes('emails') && !b.Name.includes('emails')) return -1
            if (!a.Name.includes('emails') && b.Name.includes('emails')) return 1
            return 0
          })
        }
        setBuckets(buckets)
      })()
    }
  }, [credentials]);

  useEffect(() => {
    if (Array.isArray(buckets) && buckets.length > 0) {
      setBucket(buckets[0].Name)
    }
  }, [buckets])

  useEffect(() => {
    fetchTemplates()
  }, [bucket])

  return (
    <div>
      <h2 className="text-2xl font-bold m-4">Manager</h2>

      <div className="px-4">
        <select
          name="aws_s3_bucket"
          id="aws_s3_bucket"
          className="max-w-full px-4 py-3 bg-gray-100 rounded"
          onChange={onSelectBucket}
          value={bucket}
        >
          {!bucket && (
            <option value="" disabled>Select option</option>
          )}
          {buckets.map(bucket => (
            <option key={bucket.Name} value={bucket.Name}>{bucket.Name}</option>
          ))}
        </select>
      </div>

      {!!bucket && (
        <div className="my-4 flex items-center justify-stretch gap-3 px-4">
          <Link to={`/s3/${bucket}/templates/new`} className="text-center grow px-4 py-2 bg-blue-500 text-white rounded hover:text-blue-100">
            Nuevo
          </Link>

          <Link to={`/s3/${bucket}/emails`} className="text-center grow px-4 py-2 bg-blue-500 text-white rounded hover:text-blue-100">
            Envios
          </Link>
        </div>
      )}

      <div className="flex flex-col px-4">
        <div className="border-b py-2 text-sm text-gray-400">
          Templates
        </div>
        {templates.sort(sort).map((template) => (
          <Link
            key={template.Key}
            to={`/s3/${bucket}/${template.Key}/edit`}
            className="inline-block p-4 rounded hover:bg-gray-100"
          >
            {template.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ListTemplates;
