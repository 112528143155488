// src/components/S3.js
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAWS } from '../aws-context';
import listObjects from './listObjects'

let dateOptions = {
  hourCycle: 'h24',
  year:      'numeric',
  month:     'short',
  day:       '2-digit',
  weekday:   'short',
  hour:      '2-digit',
  minute:    '2-digit',
  second:    '2-digit'
}

let Item = props => {
  let { address } = useParams()
  let label = props.item.Key.split('/').find(p => p.includes('@')) || props.item.Key || ''
  let link = [props.link, address || label].filter(Boolean).join('/')

  return (
    <div className="flex items-center gap-2 leading-none">
      <a href={props.item.url} target="_blank" rel="noreferrer" className="leading-none font-mono text-blue-600">
        {props.index.toString().padStart(4, '0')}
      </a>
      <div> - </div>
      <span className="leading-none text-gray-900 font-mono">
        {props.item.LastModified.toLocaleString('es-AR', dateOptions)}
      </span>
      <div> - </div>
      <Link to={link} className="leading-none text-gray-500">
        {label}
      </Link>
    </div>

  )
}

let Deliveries = () => {
  let navigate = useNavigate()

  let { address, bucket } = useParams()
  let [objects, setObjects] = useState([])
  let [email, setEmail] = useState(address || '')

  let { credentials } = useAWS();

  let handleKeyDown = event => {
    if (event.key === 'Enter') {
      navigate(`/s3/${bucket}/emails/${email}`);
    }
  }

  useEffect(() => {
    (async () => {
      if (!bucket) return
      setObjects([])
      let prefix = ['emails', address].filter(Boolean).join('/')
      let payload = { ...credentials, prefix, bucket }
      let objects = await listObjects(payload)
      setObjects(objects)
    })()

  }, [address, bucket, credentials])

  return (
    <div className="flex flex-col gap-y-2 py-4 px-4 bg-white h-screen overflow-y-auto">
      <div className="flex items-center gap-1">
        <div className="text-gray-500 text-sm">emails</div>
        <div className="text-gray-500 text-xs">/</div>
        <Link to={`/s3/${bucket}/emails`} className="text-blue-500 text-sm">
          {bucket}
        </Link>
        {address && (
          <>
            <div className="text-gray-500 text-xs">/</div>
            <div className="text-gray-500 text-sm">{address}</div>
          </>
        )}
      </div>
      <div>
        <div className="bg-gray-100 rounded flex">
          <input
            value={email}
            name="aws_s3_prefix"
            id="aws_s3_prefix"
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            type="email"
            placeholder="Direccion de email a buscar"
            className="grow px-3 py-2 bg-transparent text-sm"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        {objects.map((item, index) => <Item
          item={item}
          index={index + 1}
          link={['/s3', bucket, 'emails'].join('/')}
          key={item.Key}
        />)}
     </div>
    </div>
  );
};

export default Deliveries;
